import React from 'react';
import { ReactMarkdown } from '@components';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import 'swiper/swiper.scss';
import styles from './product-features.module.scss';
import Container from '@components/container';
import SectionDescription from '@components/section-description';
import { Breakpoints, classNames } from '@utils';
import Icon from '@components/icon';
import List from '@components/list';
import Button from '@components/button';
import Picture from '@components/picture';

class ProductFeatures extends React.Component {
	constructor() {
		super();

		this.swiperParams = {
			slidesPerView: 'auto',
			getSwiper: this.getSwiper.bind(this)
		};

		this.state = {
			swiper: null,
			isBeginning: true,
			isEnd: false
		};

		this.handleNext = this.handleNext.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
	}

	getSwiper(swiper) {
		if (swiper) {
			this.setState({
				swiper,
				isBeginning: swiper.isBeginning,
				isEnd: swiper.isEnd
			});

			swiper.on('reachBeginning reachEnd fromEdge slideChange', () => {
				this.setState({
					isBeginning: swiper.isBeginning,
					isEnd: swiper.isEnd
				});
			});
		}
	}

	handleNext() {
		const { swiper } = this.state;
		if (!swiper) {
			return;
		}

		swiper.slideNext();
	}

	handlePrev() {
		const { swiper } = this.state;
		if (!swiper) {
			return;
		}

		swiper.slidePrev();
	}

	renderNavigation() {
		const hideBorder = this.props.items && this.props.items.some((i) => !!i.icon);
		return (
			<div className={classNames(styles.navigation, hideBorder && styles.navigationNoBorder)}>
				{!(this.state.isBeginning && this.state.isEnd) && (
					<>
						<button
							className={classNames(styles.navButton, styles.prev)}
							onClick={this.handlePrev}
							disabled={this.state.isBeginning}
						>
							<Icon name="arrow-right" />
						</button>
						<button
							className={classNames(styles.navButton, styles.next)}
							onClick={this.handleNext}
							disabled={this.state.isEnd}
						>
							<Icon name="arrow-right" />
						</button>
					</>
				)}
			</div>
		);
	}

	renderItems() {
		const { hideCarousel, items, showTags } = this.props;
		return items.map((item, i) => {
			const { title, description, list, link, icon, altIcon, img, altImg } = item;
			const tag = (i + 1).toString().padStart(2, '0');
			return (
				<div
					key={i}
					className={classNames(
						styles.item,
						img && styles.hasImg,
						hideCarousel && styles.hideCarousel,
						icon && styles.icon
					)}
				>
					{!hideCarousel && (
						<>
							{icon && <Picture className={styles.img} sources={icon} alt={altIcon} />}
							{(!icon || showTags) && <div className={styles.tag}>{tag}</div>}
						</>
					)}

					<h3 className={styles.title}>
						<ReactMarkdown children={title} />
					</h3>
					<div className={classNames(img && styles.twoColumns)}>
						<div className={classNames(img && styles.halfContainer)}>
							{img && <Picture className={styles.picture} alt={altImg} sources={img} />}
						</div>
						<div className={classNames(img && styles.halfContainer)}>
							{description && (
								<div className={styles.description}>
									<ReactMarkdown children={description} />
								</div>
							)}
							{list && <List {...list} />}
							{link && (
								<Button className={styles.itemButton} {...link} link={link.to}>
									{link.title}
								</Button>
							)}
						</div>
					</div>
				</div>
			);
		});
	}

	render() {
		const { hideCarousel, tabletDefault, twoColumns, title, bullets, subtitle, subtitleImage, subtitle2 } =
			this.props;
		return (
			<Breakpoints>
				{(breakpoint) => {
					const carousel = !hideCarousel && ['lg', 'xl'].includes(breakpoint);

					return (
						<section
							className={classNames(
								styles.section,
								tabletDefault && styles.isTabletDefault,
								twoColumns && styles.hasTwoColumns,
								!!title && styles.hasTitle,
								bullets && styles.hasBullets
							)}
						>
							{!!title && (
								<Container small>
									<SectionDescription
										title={title}
										subtitle={subtitle}
										subtitleImage={subtitleImage}
										h3Title
									/>
									{!!subtitle2 && <h3 className={styles.subtitle2}>{subtitle2}</h3>}
								</Container>
							)}
							<div className={styles.content}>
								<Container small>
									<div className={styles.list}>
										{carousel && (
											<div className={styles.swiper}>
												{<Swiper {...this.swiperParams}>{this.renderItems()}</Swiper>}
												{this.renderNavigation()}
											</div>
										)}
										{!carousel && this.renderItems()}
									</div>
								</Container>
							</div>
						</section>
					);
				}}
			</Breakpoints>
		);
	}
}

ProductFeatures.propTypes = {
	items: PropTypes.array
};

ProductFeatures.defaultProps = {
	items: []
};

export default ProductFeatures;
