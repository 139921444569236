import React from 'react';
import styles from './carousel-quote.module.scss';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import Quote from '@components/quote';
import Container from '@components/container';
import Icon from '@components/icon';
import ClutchWidget from '@components/clutch-widget';
import { classNames, getWindow } from '@utils';
import debounce from 'lodash.debounce';

class CarouselQuote extends React.Component {
	constructor() {
		super();

		this.swiperParams = {
			getSwiper: this.getSwiper.bind(this),
			effect: 'fade',
			autoplay: { delay: 15000 },
			loop: true,
			autoHeight: true,
			slideActiveClass: styles.slideActive
		};

		this.state = {
			currentIndex: 0
		};

		this.handleScroll = debounce(this.handleScroll, 200).bind(this);
	}

	componentDidMount() {
		this.window = getWindow();

		this.window.document.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		this.window.document.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		if (!this.state.swiper) {
			return;
		}
		this.state.swiper.update();
	}

	getSwiper(swiper) {
		if (!swiper) {
			return;
		}

		this.setState({
			swiper,
			currentIndex: swiper.realIndex
		});

		swiper.on('slideChange', () => {
			this.setState({
				currentIndex: swiper.realIndex
			});
		});

		swiper.on('transitionEnd', () => {
			swiper.loopFix();
		});
	}

	goTo(i) {
		if (!this.state.swiper) {
			return;
		}

		this.state.swiper.slideTo(i);
	}

	render() {
		const { items } = this.props;

		if (!items) {
			return null;
		}

		const hasCarousel = items.length > 1;

		return (
			<section className={styles.section}>
				<Container small>
					<div className={styles.wrapper}>
						<div className={styles.icon}>
							<Icon name="quote" />
						</div>
						<div className={styles.content}>
							{items.length && (
								<div className={styles.quotes}>
									{hasCarousel && (
										<React.Fragment>
											<Swiper {...this.swiperParams}>
												{items.map((item, i) => (
													<Quote className={styles.slide} key={i} {...item} />
												))}
											</Swiper>
											<div className={styles.navigation}>
												{items.map((item, i) => (
													<button
														className={classNames(
															styles.bullet,
															i === this.state.currentIndex && styles.bulletActive
														)}
														key={i}
														// Swiper slides array count starts on index 1
														onClick={this.goTo.bind(this, i + 1)}
														dataset={item}
													/>
												))}
											</div>
										</React.Fragment>
									)}
									{!hasCarousel && <Quote {...items[0]} />}
								</div>
							)}
							<ClutchWidget className={styles.widget} />
						</div>
					</div>
				</Container>
			</section>
		);
	}
}
export default CarouselQuote;
