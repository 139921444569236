import React from 'react';
import { ReactMarkdown } from '@components';
import classNames from 'classnames';

import Icon from '@components/icon';
import styles from './list.module.scss';

const ListBullet = ({ bullet }) => {
	const cssBullets = ['circle'];

	if (cssBullets.includes(bullet)) {
		return <div className={styles.bullet} data-icon={bullet} />;
	} else {
		return <Icon className={styles.bullet} name={bullet} />;
	}
};

const List = (props) => {
	const { items, bullet, link } = props;
	return items && items.length ? (
		<ul className={styles.list}>
			{items.map((item, i) => {
				return (
					<li key={i} className={styles.item}>
						<ListBullet bullet={bullet} />
						<div className={styles.content}>
							<div className={styles.title}>
								<ReactMarkdown children={item.title} />
							</div>
							<div className={styles.title}>
								<ReactMarkdown children={item.link} />
							</div>
							{item.sublist && <List {...item.sublist} />}
						</div>
					</li>
				);
			})}
			{link && (
				<li key="link" className={classNames(styles.item, styles.link)}>
					<ReactMarkdown children={link} />
				</li>
			)}
		</ul>
	) : null;
};

List.propTypes = {};

export default List;
